import { AppRootProps } from '@grafana/data';
import { A } from './A';
import { B } from './B';
import { C } from './C';
import { D } from './D';

export type PageDefinition = {
  component: React.FC<AppRootProps>;
  icon: string;
  id: string;
  text: string;
};

export const pages: PageDefinition[] = [
  {
    component: A,
    icon: 'info-circle',
    id: 'a',
    text: 'Assistance',
  },
  {
    component: B,
    icon: 'file-alt',
    id: 'b',
    text: 'Formation',
  },
  {
    component: C,
    icon: 'file-alt',
    id: 'c',
    text: 'Rapports PDF par mail',
  },
  {
    component: D,
    icon: 'database',
    id: 'd',
    text: 'Php my admin',
  },
];
