import { AppRootProps } from '@grafana/data';
import React, { FC } from 'react';

export const A: FC<AppRootProps> = () => {
  return (
    <>
      <div>
        <h2>En cas de besoin, n&#39;hésitez pas à contacter l&#39;assistance Grafana d&#39;OID</h2>
      </div>
      <ul>
        <li>Vous pouvez envoyer un mail à hotline@oid-consultants.com</li>
        <li>Vous pouvez aussi prendre un rdv téléphonique avec l&#39;assistance OID ci-dessous :</li>
      </ul>
      <div>
        <iframe
          id="inlineFrameExample"
          title="Inline Frame Example"
          width="600"
          height="1000"
          src="https://calendly.com/adrien-blanc/rdv-tel"
        ></iframe>
      </div>
    </>
  );
};
