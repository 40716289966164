import React, { FC } from 'react';
import { AppRootProps } from '@grafana/data';

export const C: FC<AppRootProps> = () => {
  return (
    <>
      <div>
        <h2>Programmation de rapports pour un par mail</h2>
      </div>
      <div>
        <iframe
          id="inlineFrameExample"
          title="Inline Frame Example"
          width="900"
          height="1500"
          src="https://rapportspdf.clayens.oidbox.com/liste_rapports.php"
        ></iframe>
      </div>
    </>
  );
};
