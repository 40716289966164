import React, { FC } from 'react';
import { AppRootProps } from '@grafana/data';

export const B: FC<AppRootProps> = () => {
  return (
    <>
      <div>
        <h2>Télécharger ici les supports de formation Grafana</h2>
      </div>
      <div>
        <ul>
          <li>
            <a
              href="https://www.oid-consultants.com/oidbox/traceconso/sintex_np_genas/formation/OID_Formation_Grafana_Jour_1.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Support du Jour 1
            </a>
          </li>
          <li>
            <a
              href="https://www.oid-consultants.com/oidbox/traceconso/sintex_np_genas/formation/OID_Formation_Grafana_Jour_2.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Support du Jour 2
            </a>
          </li>
        </ul>
      </div>
      <div>
        <br />
        <br />
        <br />
        <h2>Informations utiles :</h2>
        <ul>
          <li>
            Pour la génération de rapport en pdf, utilisez :
            apitoken=eyJrIjoiQWozQjFpMEpxOHk4dDQ5dDNQeURiMmJuNnM1MWczUU0iLCJuIjoicmFwcG9ydF9wZGYiLCJpZCI6MX0=
          </li>
        </ul>
      </div>
    </>
  );
};
