import React, { FC } from 'react';
import { AppRootProps } from '@grafana/data';

export const D: FC<AppRootProps> = () => {
  return (
    <>
      <div>
        <h2>Accèder à php my admin pour modifier la base de données</h2>
      </div>
      <div>
        <a href="https://phpmyadmin.clayens.oidbox.com" target="_blank" rel="noreferrer">
          Cliquer ici
        </a>
      </div>
    </>
  );
};
